import React from "react";

const SectionContent = (props) => {
    return(
        <div>
            {props.children}
        </div>
    );
};

export default SectionContent;