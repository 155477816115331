import React from "react";
import '../../App.css';

function Header() {
    return(
      <header className="header">
        <h1 className="myname">Adriana Nieves</h1>
      </header>
    )
}

export default Header;